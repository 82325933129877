

$(document).ready( function () {

	// Webp image support
	// Modernizr.on('webp', function (result) {
	// if ($('html').hasClass('webp')) {
	//   $("img").each(function(){
	// 	var src = this.src;
	// 	if( src.match(/jpg|gif$/) ){
	// 		this.src = src.slice(0,-3) + 'webp';
	// 	}
	//   });
	// }
 //  });

	// Remove 300ms click delay for mobile devices
	if (jQuery.browser.mobile) {
		$(function() {
		  FastClick.attach(document.body);
		});
	}

  // HTML5 form validation API polyfill - https://github.com/hyperform/hyperform
  hyperform(window);

  // Smooth page scrolling
	$(document).on( 'click', 'a[href*="#"]:not([href="#"],[href="#sidr"])', function() {
	if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
	  var target = $(this.hash);
	  var pos = target.offset().top - 60;
	  target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
	  if (target.length) {
		$('html,body').animate({
		  scrollTop: pos
		}, 600, 'easeOutExpo');
		return false;
	  }
	}
	});

   // Add image backgrounds, if present
  Modernizr.on('webp', function (result) {
	  $('[data-bg]').each(function () {
		  var bg = $(this).data('bg');
		 //  if ($('html').hasClass('webp')) {
			// bg = bg.slice(0,-3) + 'webp';
		 //   }
		  $(this).css({"background-image": "url(" + bg + ")"});
	  });
	});


  // Trigger radio checked value
	$('[type="radio"] + label').on( 'click', function () {
		$(this).parent().find('input[type="radio"]').prop( "checked", false );
		$(this).parent().find('input[type="radio"]').prop( "checked", true );
	});

	// Trigger checkbox checked value
	$('[type="checkbox"] + label').on( 'click', function () {
		var $cb = $(this).parent().find('input[type="checkbox"]');
		if ($cb.prop( "checked")) {
			$cb.prop( "checked", false );
		} else {
			$cb.prop( "checked", true );
		}
	});

	$('[data-focus]').focus();

	$('#signupForm').on('submit', function() {
		var $pl = $('<div id="pageloader"></div>').appendTo('body');
	});



});
